import React, { useMemo } from 'react'
import { Accordion, Flex, Box, Heading, Loading, RadioGroup, Text } from 'ui'
import { Footer } from '../Footer'
import { usePositionTemplateGroups } from 'queries/company'
import { sortBy } from 'ramda'
import { FaCarSide } from 'react-icons/fa'
import { Home } from 'lucide-react'
import { TbConfetti } from 'react-icons/tb'
import { AiOutlineUser } from 'react-icons/ai'
import { RiHotelLine, RiRestaurantLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form'
import { CategoryForm, categorySchema } from '../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePositionEditor } from '../usePositionEditor'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { PositionTemplatePicker } from 'components/PositionTemplatePicker'

function getGroupIcon(groupName: string) {
  return (
    {
      Driving: <FaCarSide fontSize={18} />,
      Warehouse: <Home size={18} />,
      Event: <TbConfetti fontSize={18} />,
      Food: <RiRestaurantLine fontSize={18} />,
      'General Labor': <AiOutlineUser fontSize={18} />,
      Hospitality: <RiHotelLine fontSize={18} />,
    }[groupName] ?? <AiOutlineUser fontSize={18} />
  )
}

export function Category() {
  const { value: enableNewPositionTemplatePicker } = useGate(
    FeatureGates.EnablePositionTemplatePicker
  )
  const { positionData, setPositionData, goNext } = usePositionEditor()
  const { data, isLoading } = usePositionTemplateGroups()
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CategoryForm>({
    resolver: zodResolver(categorySchema),
    defaultValues: positionData.category,
  })
  const positionTemplateId = watch('positionTemplateId')
  const selectedGroupId = useMemo(() => {
    if (!positionTemplateId || !data) return null
    const selectedGroup = data.find((group) =>
      group.positionTemplates.some(
        (template) => Number(template.id) === Number(positionTemplateId)
      )
    )
    return selectedGroup?.id ?? null
  }, [positionTemplateId, data])

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation()
        e.preventDefault()
        handleSubmit((data) => {
          // if position template changes, lets reset requirement data subsequently saved
          if (
            data.positionTemplateId !==
            positionData.category?.positionTemplateId
          ) {
            setPositionData({
              category: data,
              requirement: undefined,
            })
          } else {
            setPositionData({
              category: data,
            })
          }
          goNext()
        })(e)
      }}
    >
      <Flex
        width={[1, '60rem']}
        maxHeight={'80vh'}
        minHeight={'40vh'}
        flexDirection={'column'}
      >
        {isLoading && !enableNewPositionTemplatePicker ? <Loading /> : null}
        {enableNewPositionTemplatePicker ? (
          <Box flex={1} overflow={'auto'}>
            <PositionTemplatePicker
              positionTemplateId={positionTemplateId}
              onSelect={(positionTemplateId) =>
                setValue('positionTemplateId', positionTemplateId)
              }
            />
          </Box>
        ) : data ? (
          <>
            <Heading level={3}>Select a Category</Heading>
            <Accordion
              defaultValue={selectedGroupId?.toString() ?? undefined}
              type={'single'}
              items={data.map((group) => ({
                id: group.id.toString(),
                trigger: (
                  <Flex>
                    {getGroupIcon(group.name)}
                    <Text fontWeight={2} ml={3}>
                      {group.name}
                    </Text>
                  </Flex>
                ),
                content: (
                  <Box pl={4}>
                    <RadioGroup
                      value={positionTemplateId}
                      name={'positionTemplateId'}
                      options={sortBy((item) => {
                        if (item.name === 'Other') return 'zzz'
                        return item.name
                      }, group.positionTemplates).map((template) => ({
                        label: template.name,
                        value: template.id,
                      }))}
                      optionStyleProps={{
                        my: 3,
                      }}
                      onChange={(value) =>
                        setValue('positionTemplateId', value as number)
                      }
                    />
                  </Box>
                ),
              }))}
            />
          </>
        ) : null}
        <Footer ctaText={'Next: Job Requirements'} />
      </Flex>
    </form>
  )
}
