import React from 'react'
import { CalendarViewContextState } from '../../context'
import { BaseWorkerCardCell } from './BaseWorkerCardCell'
import { Text } from '@workwhile/ui'

type WorkerCardProps = {
  worker?: CalendarViewContextState['state']['workers'][number]
}

export const WorkerCardCell = ({ worker }: WorkerCardProps) => {
  if (!worker) {
    return (
      <BaseWorkerCardCell
        title={
          <Text fontSize={0} color="lightText" textAlign="left">
            Finding worker ...
          </Text>
        }
      />
    )
  }
  return (
    <BaseWorkerCardCell
      avatar={{
        photoUrl: worker.profilePicUrl,
        name: worker.name,
      }}
      title={
        <Text fontSize={0} textAlign="left">
          {worker.name}
        </Text>
      }
    />
  )
}
