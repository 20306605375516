import React, { useMemo } from 'react'
import { CalendarViewContextState } from '../../context'
import { format } from 'date-fns'
import { WorkerCardCell } from './WorkerCardCell'
import { WorkerShiftCell } from './WorkerShiftCell'
import { Box, Flex } from '@workwhile/ui'

type WorkerRowProps = {
  worker: CalendarViewContextState['state']['workers'][number]
  daysOfWeek: CalendarViewContextState['state']['daysOfWeek']
}

const WorkerRow = ({ worker, daysOfWeek }: WorkerRowProps) => {
  // FIXME: Implement this
  const isThisWorkerSlotRow = false

  const workerCells = daysOfWeek.map((day) => {
    const dateString = format(day, 'yyyy-MM-dd')
    const shifts = worker.works[dateString]

    if (!shifts) {
      return (
        <Box
          as="td"
          p={3}
          key={`workers-${worker.id}-${dateString}-empty-cell`}
        />
      )
    }

    const shiftCells = shifts.map((work) => {
      return (
        <WorkerShiftCell
          key={`workers-${worker.id}-${dateString}-${work.id}`}
          work={work}
            shift={work.shift}
        />
      )
    })

    return (
      <Box
        as="td"
        verticalAlign="top"
        key={`workers-${worker.id}-${dateString}`}
      >
        <Flex
          display="flex"
          flexDirection="column"
          p={2}
          style={{
            gap: 8,
          }}
          alignItems="flex-start"
        >
          {shiftCells}
        </Flex>
      </Box>
    )
  })

  return (
    <tr>
      <WorkerCardCell worker={worker} />
      {workerCells}
    </tr>
  )
}

type WorkerRowsProps = {
  workers: CalendarViewContextState['state']['workers']
  daysOfWeek: CalendarViewContextState['state']['daysOfWeek']
}

export const WorkerRows = ({ workers, daysOfWeek }: WorkerRowsProps) => {
  const workerRows = useMemo(() => {
    const rows = workers.map((worker) => {
      return (
        <WorkerRow
          key={`workers-${worker.id}`}
          worker={worker}
          daysOfWeek={daysOfWeek}
        />
      )
    })
    return rows
  }, [workers, daysOfWeek])

  return <>{workerRows}</>
}
