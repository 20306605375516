import React, { useMemo } from 'react'
import {
  BaseWorkerShiftCell,
  BaseWorkerShiftCellProps,
} from './BaseWorkerShiftCell'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import * as Popover from '@radix-ui/react-popover'
import { useShouldEnableLTARequestCallouts } from 'hooks/useShouldEnableLTARequestCallouts'
import { Work, WorkShift } from 'typings/common_defs'
import { WorkerShiftCellDetail } from './WorkerShiftCellDetail'

type WorkerShiftCellProps = {
  work?: Pick<Work, 'status' | 'callouts' | 'startedAt' | 'completedAt'>
  shift: Pick<WorkShift, 'id' | 'location' | 'isOrientation'>
}

export const WorkerShiftCell = ({ work, shift }: WorkerShiftCellProps) => {
  const heading = shift.isOrientation ? 'Orientation' : undefined
  const shouldEnableAssignmentCallouts = useShouldEnableLTARequestCallouts()

  const valueAndVariant:
    | Pick<BaseWorkerShiftCellProps, 'variant' | 'value'>
    | undefined = useMemo(() => {
    const timezone = getLocationTimezone(shift.location)

    if (!work) {
      return {
        variant: 'info',
        value: 'Unassigned',
      }
    }

    if (work.status === 'scheduled') {
      if (shouldEnableAssignmentCallouts) {
        // callouts array is sorted in ascending order by callouts.created_at, so we need to check the status of the most recent callout.
        const callout = work.callouts?.at(-1)

        if (callout?.status === 'requested') {
          return {
            variant: 'critical',
            value: 'Callout Requested',
          }
        }
      }

      return {
        variant: 'info',
        value: `Scheduled`,
      }
    }

    if (work.status === 'started') {
      const startTime = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a z')
        : undefined

      return {
        variant: 'info',
        value: startTime ? `Clocked in at ${startTime}` : 'Working...',
      }
    }

    if (
      work.status &&
      ['completed', 'approved', 'paid', 'employer_approved'].includes(
        work.status
      )
    ) {
      const startTime = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a')
        : undefined

      const endTime = work.completedAt
        ? formatInTimeZone(work.completedAt, timezone, 'h:mm a z')
        : undefined

      return {
        variant: 'info',
        value: startTime && endTime ? `${startTime} - ${endTime}` : 'Completed',
      }
    }

    if (work.status === 'removed') {
      if (shouldEnableAssignmentCallouts) {
        // callouts array is sorted in ascending order by callouts.created_at, so we need to check the status of the most recent callout.
        const callout = work.callouts?.at(-1)

        if (callout?.status === 'approved') {
          return {
            variant: 'info',
            value: 'Callout Approved',
          }
        }
      }

      return {
        variant: 'info',
        value: 'Removed',
      }
    }

    if (work.status === 'admin_review') {
      return {
        variant: 'info',
        value: 'Under Review',
      }
    }

    if (work.status === 'employer_review') {
      return {
        variant: 'critical',
        value: 'Review Required',
      }
    }

    if (work.status === 'bailed') {
      return {
        variant: 'critical',
        value: 'No show',
      }
    }

    if (
      work?.status &&
      [
        'employer_cancelled',
        'rejected',
        'worker_declined_req',
        'system_declined',
      ].includes(status)
    ) {
      return undefined
    }

    return undefined
  }, [work, shouldEnableAssignmentCallouts])

  if (!valueAndVariant) {
    return null
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <BaseWorkerShiftCell
          heading={heading}
          {...valueAndVariant}
          borderStyle={work?.status ? 'dashed' : 'solid'}
        />
      </Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        sideOffset={5}
        asChild
        style={{ zIndex: 1 }}
      >
        <WorkerShiftCellDetail work={work} shift={shift} />
      </Popover.Content>
    </Popover.Root>
  )
}
