import React from 'react'

export interface PositionTemplatePickerProps {
  positionTemplateId?: number
  onSelect: (positionTemplateId: number) => void
}

export function PositionTemplatePicker(props: PositionTemplatePickerProps) {
  return (
    <div>
      Placeholder for new position template picker {props.positionTemplateId}
    </div>
  )
}
