import React, { forwardRef } from 'react'
import {
  Box,
  Button,
  Card,
  CardProps,
  Flex,
  Heading,
  Link,
  Pluralize,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import * as Popover from '@radix-ui/react-popover'

import { CircleDollarSign, Contact, MapPin, Users, X } from 'lucide-react'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { formatCurrency } from 'lib/string'
import { getShiftBonus, getShiftPay } from 'lib/payment'
import { ShiftDetailBodySection } from '../ShiftDetailBodySection'
import { CalendarViewShift } from 'pages/AssignmentDetailPage/types'

type ShiftCellDetailProps = Omit<
  CardProps,
  'ref' | 'children' | 'shadowless'
> & {
  shift: CalendarViewShift
}

export const ShiftCellDetail = forwardRef<HTMLDivElement, ShiftCellDetailProps>(
  ({ shift, ...rest }, ref) => {
    const { space } = useAppTheme()
    const timezone = getLocationTimezone(shift.location)
    const startTime = formatInTimeZone(shift.startsAt, timezone, 'h:mm a')
    const endTime = formatInTimeZone(shift.endsAt, timezone, 'h:mm a z')
    const day = formatInTimeZone(shift.startsAt, timezone, 'EEEE, MMMM d')

    const pay = getShiftPay(shift)
    const bonus = getShiftBonus(shift)

    return (
      <Card ref={ref} width={[200, 264]} {...rest}>
        <Popover.Close
          asChild
          style={{ position: 'absolute', top: space[1], right: space[1] }}
        >
          <Button
            variant="text"
            p={1}
            style={{
              height: 'auto',
              padding: space[1],
            }}
          >
            <X size={18} />
          </Button>
        </Popover.Close>
        <Box>
          <Heading level={4} fontWeight="400" my={0}>
            {day}
          </Heading>
          <Text
            color="lightText"
            fontSize={1}
          >{`${startTime} - ${endTime}`}</Text>
        </Box>
        <ShiftDetailBodySection Icon={Contact} mt={3}>
          <Text fontSize={2}>{shift.position.name}</Text>
        </ShiftDetailBodySection>
        <ShiftDetailBodySection Icon={MapPin} mt={3}>
          <Text fontSize={2}>{shift.location.name}</Text>
        </ShiftDetailBodySection>
        <ShiftDetailBodySection Icon={CircleDollarSign} mt={3}>
          <Text fontSize={2}>
            {formatCurrency(pay)}
            {shift.payLumpSum ? ' per worker' : '/hour per worker'}
            {bonus && Number(bonus) > 0
              ? ` • Bonus: ${formatCurrency(bonus)}`
              : null}
          </Text>
        </ShiftDetailBodySection>
        <ShiftDetailBodySection Icon={Users} mt={3}>
          <Text fontSize={2}>
            <Pluralize
              count={shift.numWorkersFilled || 0}
              oneText={'worker'}
              manyText={'workers'}
            />{' '}
            scheduled
          </Text>
        </ShiftDetailBodySection>

        <Flex mt={4} justifyContent="flex-end">
          <Button as={Link} to={`/shift/${shift.id}`} kind="medium">
            <Text color="white">View Shift</Text>
          </Button>
        </Flex>
      </Card>
    )
  }
)

ShiftCellDetail.displayName = 'ShiftCellDetail'
